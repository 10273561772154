<template>
  <el-dialog title="续费" class="dialog" :close-on-click-modal="false" :visible.sync="renewVisible">
    <el-form :model="formData" ref="dataForm" :rules="rules">
      <el-form-item label="活动名称" label-width="100px" prop="name">
        <el-input v-model="formData.name" autocomplete="off" :disabled="true"></el-input>
      </el-form-item>
      <!-- <el-form-item label="续费时长" label-width="100px" prop="region">
        <el-select v-model="formData.region" placeholder="请选择续费时长">
          <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.price"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="续费时长" prop="useTimeLength" label-width="100px">
        <el-input placeholder="请输入天数" type="number" v-model="formData.useTimeLength">
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="续费金额" label-width="100px">
        <div>¥ {{ formData.region }}</div>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="renewVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      renewVisible: false, //表单显示状态
      formData: {}, //表单数据
      rules: {
        region: [{ required: true, message: '请选择续费时长', trigger: 'change' }]
      }
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    formTitle() {}
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {}
        } else {
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {}
      }
    }
  },
  methods: {
    submit() {
      this.formData.useTimeLength = Number(this.formData.useTimeLength)
	  let operation = 'addProjectTime'
	  if(this.formData.model){
		operation = 'addModelTime'
		delete this.formData.model
	  }
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const data = this.formData
        const response = await http.POST('/jw-admin', {
          module: 'project',
          operation: operation,
          data
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.renewVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
